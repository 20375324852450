import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Router} from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthService } from '../auth/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class ValidarTokenGuard implements CanActivate, CanLoad {

  constructor(private authservice:AuthService,
              private router:Router){}

  canActivate(
    //route: ActivatedRouteSnapshot,
    //state: RouterStateSnapshot
    ): Observable<boolean> | boolean {
    return this.authservice.validarToken()
                .pipe(
                  tap(valid => {
                    if(!valid){ 
                      this.router.navigateByUrl('/auth')
                    }
                  })
                )
  }
  canLoad(
    // route: Route,
    //segments: UrlSegment[]
    ): Observable<boolean> | boolean{
    return this.authservice.validarToken()
                .pipe(
                  tap(valid => {
                    if(!valid){
                       this.router.navigateByUrl('/auth')
                    }
                  })
                )
  }
}
