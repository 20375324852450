// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  baseUrl:'https://copydata-2dn8w.ondigitalocean.app/api/',
  // baseUrl:'http://localhost:8080/api/',
  mapboxToken:'pk.eyJ1IjoiY2VzYXJpdnEiLCJhIjoiY2tyZjN0YzR4MzNxZTJxcjI0cHF1M2tleiJ9.E7oyDDU8VFAYnYyRvCwClQ',
  remoraToken:'http://ws3.remora.com.mx/min/SessionManager/StartSession/copydata/628DcF749'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
