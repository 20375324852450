import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from "../../../environments/environment";
import { catchError, map, tap } from "rxjs/operators";
import { of, Observable } from 'rxjs';

import { AuthResponse, IUser, AuthResponseT, ITech } from '../interfaces/login.interfaces'; 

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private baseUrl:string =environment.baseUrl;
  private _user!:IUser;
  private _tech!:ITech;

  get user(){
    return {...this._user};
  }

  get tech(){
    return {...this._tech};
  }

  constructor(private http: HttpClient) { }

  login(email:string, password:string){
    const url = `${this.baseUrl}auth/login`
    const body = {email,password}

    return this.http.post<AuthResponse>(url,body)
      .pipe(
        tap(resp =>{
          if (resp.ok) {
            localStorage.setItem('token', resp.token!)
            this._user = resp.user
          }
          console.log(resp);
        }),
        map(resp => resp.ok),
        catchError(err=>of(err.error.msg))
      )
  }

  login_technical(email:string, password:string){
    const url = `${this.baseUrl}auth/login_technical`
    const body = {email,password}

    return this.http.post<AuthResponseT>(url,body)
      .pipe(
        tap(resp =>{
          if (resp.ok) {
            localStorage.setItem('token', resp.token!)
            this._tech = resp.technical;
          }
          console.log(resp);
        }),
        map(resp => resp.ok),
        catchError(err=>of(err.error.msg))
      )
  }

  validarToken():Observable<boolean>{
    const url = `${this.baseUrl}auth/renew`;
    const headers = new HttpHeaders()
          .set('x-token', localStorage.getItem('token') || '' )
    return this.http.get<AuthResponse>(url,{headers})
           .pipe(
             map(resp=>{
               localStorage.setItem('token',resp.token!)
               this._user = resp.user;
               return resp.ok;
             }),
             catchError(err => of(false))
           )
  }

  validarTokenTechnical():Observable<boolean>{
    const url = `${this.baseUrl}auth/renew/technical`;
    const headers = new HttpHeaders()
          .set('x-token', localStorage.getItem('token') || '' )
    return this.http.get<AuthResponseT>(url,{headers})
           .pipe(
             map(resp=>{
               localStorage.setItem('token',resp.token!)
               this._tech = resp.technical; 
               return resp.ok;
             }),
             catchError(err => of(false))
           )
  }

  logout(){
    // localStorage.removeItem('token');
    localStorage.clear();
  }
}
