import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProtectedModule } from './protected/protected.module';
import { ValidarTokenGuard } from './guards/validar-token.guard';
import { ValidarTokenGuardTechnical } from './guards/validar-token-technical.guard';

const routes: Routes = [
  {
    path:'auth',
    loadChildren:()=>import('./auth/auth.module').then(m=>m.AuthModule)
  }, 
  {
    path:'dashboard',
    loadChildren:()=>import('./protected/protected.module').then(m=>m.ProtectedModule),
    canActivate:[ValidarTokenGuard],
    canLoad:[ValidarTokenGuard]
  },
  {
    path:'technical',
    loadChildren:()=>import('./protected-technical/protected-technical.module').then(m=>m.ProtectedTechnicalModule),
    canActivate:[ValidarTokenGuardTechnical],
    canLoad:[ValidarTokenGuardTechnical]
  },
  {
    path:'screen',
    loadChildren:()=>import('./copydata/copydata.module').then(m=>m.CopydataModule)
  },
  {
    path:'**',
    redirectTo:'auth'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{
    useHash:false
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
